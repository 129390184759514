import React, { useState, useEffect } from 'react';
import './css/Landingpage.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function Partners({ partners }) {
  const [partnerImages, setPartnerImages] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchImages = () => {
      const images = {};
      for (const partner of partners) {
        try {
          const imageUrl = `/assets/Partners/${partner.img}`;  // Reference the local image
          images[partner.name] = imageUrl;
        } catch (error) {
          console.error(`Error loading image for ${partner.name}:`, error);
        }
      }
      setPartnerImages(images);
      setIsLoading(false);
    };

    fetchImages();
  }, [partners]);

  return (
    <div className="slider" id='partners'>
      <div className="slide-track2">
        {(isLoading ? Array.from({ length: partners.length }).map((_, index) => (
          <div className="slide" key={index}>
            <Skeleton height={100} width={100} className="logo" />
          </div>
        )) : partners).concat(partners).map((partner, index) => (
          <div className="slide" key={index}>
            <a rel="noreferrer" href={partner.link} target="_blank">
              {partnerImages[partner.name] ? (
                <img
                  alt={partner.name}
                  className="logo"
                  id="logo"
                  src={partnerImages[partner.name]}
                />
              ) : (
                <Skeleton height={100} width={200} className="logo" />
              )}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Partners;
